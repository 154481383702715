/* Container needed to position the overlay. Adjust the width as needed */
.artwork-container {
    position: relative;
    max-width: 300px;
}

/* Make the image to responsive */
.artwork {
    display: block;
    width: 100%;
    height: auto;
}

/* Polaris ovverrides */

/*@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 110em) {
    .Polaris-Page {
        margin-left: 60px !important;
        max-width: 1420px;
    }
    .Polaris-TopBar__Contents {
        padding-left: 60px !important;
        max-width: 1420px;
    }
}

.Polaris-Card {
    border-radius: 4px !important;
    box-shadow: 0 0 0 1px #e3e8ee !important;
}
.Polaris-TopBar__SearchField {
    margin-left: 0 !important;;
}
!* Theme overrides *!
.Polaris-TopBar {
    border-bottom: 0.1rem solid var(--p-divider, #dfe3e8);

    box-shadow: none !important;
}

.Polaris-TopBar__LogoContainer {
    background-color: var(--p-background, #f4f6f8);
    border-right: 0.1rem solid var(--p-divider, #dfe3e8);
}

.Polaris-Frame__Content {
    background: white !important;
}*/


/* The overlay effect - lays on top of the container and over the image */
.artwork-overlay {
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    right: 0;
    background: rgba(255, 255, 255, 1); /* Black see-through */
    transition: .5s ease;
    opacity:1;

    padding: 10px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.artwork-icon {
    font-size: 20px;
    color: #3f4eae;
    width: 20px;
    vertical-align: -0.225em;
}


.section-icon {
    color: #3f4eae;
    width: 20px;
    vertical-align: -0.225em;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #0b1438;
    opacity: .89;
}


.hero__background-image {
    height: 100vh;
    background-size:cover;
}
